.skeleton {
    margin-top: 10px;
}

.skeleton-header {
    width: 100%;
    height: 60px;
    background: #ddd;
    border-radius: 5px;
    animation: moving-shadow 2s infinite linear;
}

@keyframes moving-shadow {
    0% {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    }
    50% {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
    100% {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    }
}

.skeleton-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.skeleton-card {
    width: 48%;
    height: 163px;
    background: #ddd;
    border-radius: 5px;
    animation: moving-shadow 2s infinite linear;
}