.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* semi-transparent black */
    z-index: 999;
    /* high z-index to overlay on top of other elements */
}